<template>
  <div class="container pt-3">
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          {{ myTitle }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <Heading heading="h4" :content="myTitle"> </Heading>
    <hr />
    <el-input
      placeholder="User / Email / Logs"
      v-model="studentQuery"
      class="input-with-select"
      @keyup.enter.native="searchUsers"
    >
      <el-button slot="append" icon="el-icon-search" @click="searchUsers">
        Search
      </el-button>
    </el-input>
    <div class="text-center mt-3 mb-3">
      <el-pagination
        background
        @current-change="setPage"
        :page-size="pageSize"
        :current-page="page"
        :total="resultsTotal"
        :pager-count="10"
        layout="prev, pager, next"
      >
      </el-pagination>
    </div>
    <el-table class="mt-4" :data="students" style="width: 100%" border>
      <el-table-column label="Users">
        <template slot-scope="scope">
          <router-link
            :to="{
              name: 'SuperAdminViewUser',
              params: {
                uid: scope.row.causer_id
              }
            }"
          >
            <b v-if="scope.row.causer">
              {{
                user.displayName(
                  scope.row.causer.first_name,
                  scope.row.causer.last_name
                )
              }}
            </b>
            <span v-if="scope.row.causer">
              - [{{ scope.row.causer.email }}]
            </span>
          </router-link>
        </template>
      </el-table-column>
      <el-table-column prop="description" label="Logs"> </el-table-column>
      <el-table-column label="Date" width="120">
        <template slot-scope="scope">
          {{ instant.formatGoogleTime(scope.row.updated_at) }}
        </template>
      </el-table-column>
    </el-table>
    <div class="text-center mt-3">
      <el-pagination
        background
        @current-change="setPage"
        :page-size="pageSize"
        :current-page="page"
        :total="resultsTotal"
        :pager-count="10"
        layout="prev, pager, next"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Breadcrumb from "@/components/Breadcrumb";
import userApi from "@/apis/user";
import { user, instant } from "@ivy-way/material";

export default {
  metaInfo() {
    return {
      title: `${this.myTitle} - ${this.CompanyName} `
    };
  },

  components: {
    Breadcrumb
  },

  mixins: [],

  props: [],
  data() {
    return {
      studentQuery: null,
      form: {
        uid: null,
        oCoins: 0,
        coins: 0,
        type: 1,
        all_roles: []
      },
      students: null,
      student: null,
      showStudent: false,
      resultsTotal: null,
      showRole: false,
      pageSize: 10
    };
  },
  computed: {
    myTitle() {
      return "Activities";
    },
    user() {
      return user;
    },
    instant() {
      return instant;
    },
    page() {
      let page = 1;
      if (this.$route.query.page === undefined) {
        page = 1;
      } else {
        page = this.$route.query.page;
      }
      return parseInt(page);
    }
  },
  watch: {},

  mounted() {
    if (this.$route.query.search) {
      this.studentQuery = this.$route.query.search;
    }
    this.getUsersLogs();
  },

  methods: {
    searchUsers() {
      this.routerPush({
        params: { ...this.$route.params },
        query: {
          ...this.$route.query,
          page: 1,
          search: this.studentQuery !== "" ? this.studentQuery : null
        }
      });
    },
    setPage(page) {
      this.routerPush({
        params: { ...this.$route.params },
        query: { ...this.$route.query, page: page }
      });
    },
    async getUsersLogs() {
      let query = this.studentQuery;
      this.loading = true;
      const students = await userApi.getUsersActivities({
        keyword: query !== "" ? query : null,
        page: this.page
      });
      this.loading = false;
      this.students = students.data;
      this.resultsTotal = students.total;
      this.pageSize = students.per_page;
    }
  }
};
</script>

<style scoped></style>
